import { useState } from 'react';
// material
import { styled } from "@mui/material/styles";
import { Card, Container, Stack, Typography } from "@mui/material";

// components
import Page from "../../components/Page";
import MHidden from "../../components/MHidden";
import LoginForm from "./LoginForm";
import ForgotPasswordForm from "./ForgotPasswordForm";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

const EmText = styled("span")(({ theme }) => ({
  fontWeight: "bold",
  color: "#476CEC",
}));

// ----------------------------------------------------------------------

export default function Login() {
  const [toggleForgotPassword, setToggleForgotPassword] = useState(false);

  return (
    <RootStyle title="Login">
      <MHidden width="mdDown">
        <SectionStyle>
          <Stack
            direction="column"
            alignItems="center"
            textAlign="center"
            sx={{ mb: 10 }}
          >
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              프리즘 <EmText>B</EmText>ack <EmText>T</EmText>est
              <EmText> S</EmText>ystem에
              <br/>
              오신것을 환영합니다
            </Typography>
            <img
              src="/static/Frism_combination_default_vertical.png"
              alt="login"
              style={{ maxWidth: 250 }}
            />
          </Stack>
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="column" textAlign="center" spacing={4}>
            {!toggleForgotPassword ? (
                <>
                  <Typography variant="h4"></Typography>
                  <LoginForm toggleForm={() => setToggleForgotPassword(true)}/>
                </>) :
              (
                <>
                  <Typography variant="h4">비밀번호 재설정</Typography>
                  <Typography variant="subtitle2">가입했던 이메일로 <br/> 임시 비밀번호를 전송해드립니다.</Typography>
                  <ForgotPasswordForm toggleForm={() => setToggleForgotPassword(false)}/>
                </>
              )
            }
          </Stack>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
