import { useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useSetRecoilState } from "recoil";
import * as Yup from "yup";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import closeFill from "@iconify/icons-eva/close-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import { Alert, Divider, IconButton, InputAdornment, Stack, TextField, Typography, } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import MIconButton from "@components/button/MIconButton";
import useIsMountedRef from "@hooks/useIsMountedRef";
import { authStateAtom } from "@states/auth";
import { hasActiveProMembership, hasActiveEliteMembership } from "@utils/auth";
import axios from "@utils/axios";
import { setSession } from "@utils/jwt";
import { UserType } from "../../types/auth";

const login = async (email, password) => {
  const loginFormData = new FormData();
  loginFormData.append("username", email);
  loginFormData.append("password", password);

  const response = await axios({
    method: "post", url: "auth/login", data: loginFormData, headers: { "Content-Type": "multipart/form-data" },
  });

  const user = response.data;
  setSession(user.access_token);

  return user;
};

const get_me = async () => {

  const response = await axios({
    method: "get",
    url: "users/me",
  });

  return response.data;
};

export default function LoginForm({ toggleForm }: { toggleForm: () => void }) {
  const setAuthStates = useSetRecoilState(authStateAtom);
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("이메일이 유효하지 않습니다.")
      .required("이메일을 입력해주세요."),
    password: Yup.string().required("비밀번호를 입력해주세요."),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: true,
      afterSubmit: ""
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        await login(values.email, values.password);
        const user: UserType = await get_me();

        setAuthStates({
          isInitialized: true,
          isAuthenticated: true,
          hasProMembership: user.isSuperuser || hasActiveProMembership(user.memberships),
          hasEliteMembership: user.isSuperuser || hasActiveEliteMembership(user.memberships),
          user: user
        });

        // enqueueSnackbar(user.name + "님, 반갑습니다.🥳", {
        //   variant: "success",
        //   action: (key) => (
        //     <MIconButton size="small" onClick={() => closeSnackbar(key)}>
        //       <Icon icon={closeFill}/>
        //     </MIconButton>
        //   ),
        // });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error: any) {
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          if (error.detail === "LOGIN_BAD_CREDENTIALS")
            setErrors({ afterSubmit: "아이디 또는 비밀번호가 맞지 않습니다." });
          else if (error.detail === "LOGIN_FAILED_LIMIT_REACHED")
            setErrors({ afterSubmit: "로그인 시도가 5회 이상 실패하여 임시 비밀번호가 해당 이메일로 전송되었습니다." });
          else if (error.detail === "LOGIN_USER_INACTIVE")
            setErrors({ afterSubmit: "비활성화된 유저입니다." });

          // setErrors({ afterSubmit: error.detail });
        }
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="이메일"
            {...getFieldProps("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="비밀번호"
            {...getFieldProps("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill}/>
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          {errors.afterSubmit && (
            <Alert severity="error">{errors.afterSubmit}</Alert>
          )}
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          {/* <FormControlLabel
            control={
              <Checkbox
                {...getFieldProps("remember")}
                checked={values.remember}
              />
            }
            label="로그인 유지"
          /> */}

        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          로그인
        </LoadingButton>

        <Stack
          justifyContent="space-between"
          sx={{ my: 3 }}
          spacing={3}
        >
          <Divider/>
          <Typography
            align="left"
            onClick={() => toggleForm && toggleForm()}
            variant="subtitle2"
            sx={{ color: "text.disabled", textDecoration: "underline", cursor: "pointer" }}
            noWrap={true}
          >
            비밀번호를 잊어버리셨나요?
          </Typography>
        </Stack>

      </Form>
    </FormikProvider>
  );
}
